import { NavigateFunction } from 'react-router-dom'
import { urlList } from '../constants/constants'
import { OfrDocumentTemplatePageExtended } from '../pages/OfrDocumentTemplateDetailsPage/OfrDocumentTemplateDetailsPage'
import { OfrDocumentTemplate } from '../store/reducers/ofrDocumentTemplateReducer'
import apiService from './apiService'

const baseUrl = urlList.OFR_DOCUMENT_TEMPLATES

const getOfrDocumentTemplateList = async () => {
  return apiService
    .fetchItems(baseUrl)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

interface createOfrDocumentTemplateProps {
  documentId: string
  dataSource: string
  title: string
  navigate: any
}
const createOfrDocumentTemplate = async ({
  documentId,
  dataSource,
  title,
  navigate,
}: createOfrDocumentTemplateProps) => {
  const params = {
    documentId,
    dataSource,
    title,
  }

  return apiService
    .saveItem(`${baseUrl}`, navigate, params)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios create error', error)
      throw error
    })
}

interface deleteOfrDocumentTemplateProps {
  documentId: string
  navigate: any
}

const deleteOfrDocumentTemplate = async ({
  documentId,
  navigate,
}: deleteOfrDocumentTemplateProps) => {
  return apiService
    .deleteItem(`${baseUrl}/${documentId}`, navigate)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios delete error', error)
      throw error
    })
}

interface getOfrDocumentTemplateProps {
  documentId: string
}

const getOfrDocumentTemplate = async ({
  documentId,
}: getOfrDocumentTemplateProps) => {
  return apiService
    .fetchItems(`${baseUrl}/${documentId}`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

const getOfrDocumentTemplateImages = async ({
  documentId,
}: getOfrDocumentTemplateProps) => {
  return apiService
    .fetchItems(`${baseUrl}/${documentId}/document/images`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

interface UpdateOfrDocumentTemplateProps {
  documentId: string
  navigate: NavigateFunction
  documentTemplate: OfrDocumentTemplate
}

const updateOfrDocumentTemplate = async ({
  documentId,
  navigate,
  documentTemplate,
}: UpdateOfrDocumentTemplateProps) => {
  const params = {
    formPages: documentTemplate.formPages,
  }
  return apiService
    .updateItem(`${baseUrl}/${documentId}`, navigate, params)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

interface GetBlockNextIdProps {
  ofrDocumentTemplatePages: OfrDocumentTemplatePageExtended[]
  pageId: string
}

const getBlockNextId = ({
  ofrDocumentTemplatePages,
  pageId,
}: GetBlockNextIdProps) => {
  const idList: number[] = (ofrDocumentTemplatePages
    ?.filter((page) => pageId === page.id)
    .flatMap((page) =>
      page.extendedBlocks?.map((block) => (!!block.id ? Number(block.id) : 0))
    )
    .filter((id: number | undefined) => id !== undefined) as number[]) || [0]
  const nextId = Math.max(...idList) + 1

  return nextId.toString()
}

const ofrDocumentTemplateService = {
  getOfrDocumentTemplateList,
  createOfrDocumentTemplate,
  deleteOfrDocumentTemplate,
  getOfrDocumentTemplate,
  getOfrDocumentTemplateImages,
  updateOfrDocumentTemplate,
  getBlockNextId,
}

export default ofrDocumentTemplateService
