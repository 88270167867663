import {
  CheckSquareOutlined,
  EditOutlined,
  FieldBinaryOutlined,
  FileTextOutlined,
  ReadOutlined
} from '@ant-design/icons'
import { Select } from 'antd/lib'
import { t } from 'i18next'
import { MouseEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { OfrBlockType, ofrBlockTypes } from '../constants/constants'

const CustomizedLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

interface SelectOfrBlockTypeProps {
  defaultValue?: OfrBlockType
  value?: OfrBlockType
  onChange?: (value: OfrBlockType | undefined) => void
  onClick?: (e: MouseEvent<HTMLDivElement>) => void
  style?: Object
  loading?: boolean
}

const SelectOfrBlockType = ({
  defaultValue,
  value,
  onChange,
  onClick,
  style,
  loading,
}: SelectOfrBlockTypeProps) => {
  const [selectedValue, setSelectedValue] = useState<OfrBlockType | undefined>(
    defaultValue
  )

  const blockTypeIcons = {
    [ofrBlockTypes.CHECKBOX]: <CheckSquareOutlined />,
    [ofrBlockTypes.EXPECTED_DATA]: <EditOutlined />,
    [ofrBlockTypes.PAGINATION]: <ReadOutlined />,
    [ofrBlockTypes.PROJECT_NUMBER]: <FieldBinaryOutlined />,
    [ofrBlockTypes.SIGNATURE]: <FileTextOutlined />,
  }

  const options = [
    {
      label: t('selectOfrBlockType.selectType') || '',
      value: ofrBlockTypes.NONE,
    },
    {
      label: (
        <CustomizedLabel>
          {blockTypeIcons[ofrBlockTypes.CHECKBOX]}
          {t('selectOfrBlockType.checkbox') || ''}
        </CustomizedLabel>
      ),
      value: ofrBlockTypes.CHECKBOX,
    },
    {
      label: (
        <CustomizedLabel>
          {blockTypeIcons[ofrBlockTypes.EXPECTED_DATA]}
          {t('selectOfrBlockType.expectedData') || ''}
        </CustomizedLabel>
      ),

      value: ofrBlockTypes.EXPECTED_DATA,
    },
    {
      label: (
        <CustomizedLabel>
          {blockTypeIcons[ofrBlockTypes.PAGINATION]}
          {t('selectOfrBlockType.pagination') || ''}
        </CustomizedLabel>
      ),

      value: ofrBlockTypes.PAGINATION,
    },
    {
      label: (
        <CustomizedLabel>
          {blockTypeIcons[ofrBlockTypes.PROJECT_NUMBER]}
          {t('selectOfrBlockType.projectNumber') || ''}
        </CustomizedLabel>
      ),
      value: ofrBlockTypes.PROJECT_NUMBER,
    },
    {
      label: (
        <CustomizedLabel>
          {blockTypeIcons[ofrBlockTypes.SIGNATURE]}
          {t('selectOfrBlockType.signature') || ''}
        </CustomizedLabel>
      ),
      value: ofrBlockTypes.SIGNATURE,
    },
  ]

  useEffect(() => {
    if (value) {
      setSelectedValue(value)
    }
  }, [value])

  const handleChange = (value: OfrBlockType) => {
    setSelectedValue(value)
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <Select
      style={style}
      onChange={handleChange}
      value={selectedValue}
      options={options}
      placeholder={t('selectOfrBlockType.selectType')}
      loading={loading}
      onClick={onClick}
    />
  )
}

export default SelectOfrBlockType
