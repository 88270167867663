import { CloseOutlined, InboxOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { t } from 'i18next'
import { useMemo } from 'react'
import Moment from 'react-moment'
import { useNavigate, useParams } from 'react-router-dom'
import {
  BoldText,
  SubHeader as SmartFunctionSubHeader,
} from '../../../../components'
import {
  NO_DATA_PLACEHOLDER,
  dateFormat,
} from '../../../../constants/constants'
import { useAlgorithmRunDetails, useWorkspaceDetails } from '../../../../hooks'
import {
  DeviatingOfrBlock,
  QualityReviewRunResult,
} from '../../../../store/reducers/workspaceReducer'
import { routePaths } from '../../../RootPage'
import {
  SmartFunctionMainSubHeader,
  SmartFunctionSubHeaderCell,
  SmartFunctionSubHeaderCellContainer,
  SmartFunctionSubHeaderCellSection,
  SmartFunctionSubHeaderLine,
} from '../../components/SmartFunctionHeader'

const QualityReviewSmartFunctionDocumentDetailsPageHeader = () => {
  const { workspaceId, algorithmRunId, filename } = useParams()
  const navigate = useNavigate()

  const { selectedAlgorithmRun } = useAlgorithmRunDetails({
    algorithmRunId,
    workspaceId,
  })

  const smartFunctionResult = useMemo(
    () => selectedAlgorithmRun?.runResult as QualityReviewRunResult,
    [selectedAlgorithmRun?.runResult]
  )
  const smartFunctionDocument: DeviatingOfrBlock[] | null = useMemo(() => {
    if (!smartFunctionResult) return null
    const arrayKey = Object.keys(smartFunctionResult).find(
      (key) => key === filename
    )
    if (!arrayKey) return null
    return smartFunctionResult[arrayKey] as DeviatingOfrBlock[]
  }, [filename, smartFunctionResult])

  const { selectedWorkspace } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })

  const handleCloseDetails = () => {
    navigate(
      `${routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_OFR_QR}/${selectedWorkspace?.id}/${selectedAlgorithmRun?.id}`
    )
  }

  return (
    <SmartFunctionSubHeader>
      <Tooltip
        title={t('smartFunctionDetailsPage.backButtonTooltip')}
        placement="right"
      >
        <Button
          size="middle"
          icon={<CloseOutlined />}
          onClick={handleCloseDetails}
        />
      </Tooltip>
      <SmartFunctionMainSubHeader>
        <SmartFunctionSubHeaderLine>
          <SmartFunctionSubHeaderCell>
            <BoldText>{filename || NO_DATA_PLACEHOLDER}</BoldText>
          </SmartFunctionSubHeaderCell>
          <SmartFunctionSubHeaderCellContainer>
            <SmartFunctionSubHeaderCellSection>
              {selectedAlgorithmRun?.actionDate ? (
                <Moment
                  local
                  date={selectedAlgorithmRun?.actionDate}
                  format={dateFormat.PRIMARY}
                />
              ) : (
                NO_DATA_PLACEHOLDER
              )}
            </SmartFunctionSubHeaderCellSection>
          </SmartFunctionSubHeaderCellContainer>
        </SmartFunctionSubHeaderLine>
        <SmartFunctionSubHeaderLine
        >
          <SmartFunctionSubHeaderCell>
            <InboxOutlined />
            <div
              style={{
                paddingLeft: '8px',
              }}
            >
              {smartFunctionDocument?.length} {t('Found')}
            </div>
          </SmartFunctionSubHeaderCell>
          <SmartFunctionSubHeaderCell>
            {selectedAlgorithmRun?.actionBy?.displayName || NO_DATA_PLACEHOLDER}
          </SmartFunctionSubHeaderCell>
        </SmartFunctionSubHeaderLine>
      </SmartFunctionMainSubHeader>
    </SmartFunctionSubHeader>
  )
}

export default QualityReviewSmartFunctionDocumentDetailsPageHeader
